import Apiservice from '../../api.service';
import { API_URL } from '../../../../common/config.js'
const apiService = Apiservice;


export default class StudentSettingService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student`;
    }

    paginate() {
        let url = `${this.#api}/setting`;
        return apiService.get(url);
    }

    // update(userId,studentSettingId, data) {
    //     let url = `${this.#api}/setting/${studentSettingId}`
    //     return apiService.put(url, data);
    // }

    store(data) {
        let url = `${this.#api}/setting`
        return apiService.post(url, data);
    }

    show() {
        let url = `${this.#api}/setting`
        return apiService.get(url);
    }

    // delete(userId, studentSettingId) {
    //     let url = `${this.#api}/setting/${studentSettingId}`
    //     return apiService.delete(url);
    // }

    getGrades(data = {}) {
        let url = `${this.#api}/grades`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getAllStudentGrades(data = {}) {
        let url = `${this.#api}/student-grades`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    getCurrentGrades() {
        let url = `${this.#api}/current-grade`;
        return apiService.get(url);
    }
    getTeachers() {
        let url = `${this.#api}/teachers`;
        return apiService.get(url);
    }

    // saveOptionalCourses(data) {
    //     let url = `${this.#api}/student/save/optional-courses`;
    //     return apiService.post(url,data);
    // }
}
