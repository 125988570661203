var domain = (function(){
    var i=0,domain=document.domain,p=domain.split('.'),s='_gd'+(new Date()).getTime();
    while(i<(p.length-1) && document.cookie.indexOf(s+'='+s)==-1){
        domain = p.slice(-1-(++i)).join('.');
    }
    return domain;
})();
var maindomain = domain.replace(/^[^.]+\./g, "");
maindomain = process.env.VUE_APP_PROTOCOL+'api.'+maindomain+'/';

export const API_URL = maindomain;
export default API_URL;
