<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Exams & results
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Exams & results
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="5">
                  <v-text-field class="form-control" v-model="search.name"
                                label="Search by exam name" outlined clearable
                                v-on:keyup.enter="getAllExams()" dense>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                      outlined
                      dense
                      :items="grades"
                      item-text="grade_title"
                      item-value="grade_id"
                      label="Semester/Level"
                      clearable
                      v-model="search.grade_id"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn :loading="loading" @click.prevent="getAllExams()"
                         class="btn btn-primary w-35 float-right" dark>
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="wrap-column">Title</th>
                  <th class="wrap-column text-left">Class</th>
                  <th class="wrap-column">Result</th>
                  <th class="text-center">Rank</th>

                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="exams.length > 0" v-for="(item, index) of exams" :key="index">
                  <td style="max-width: 200px !important; white-space: pre-wrap;">
                    <span class="font-weight-bold">{{ item.exam_title ? item.exam_title : '-' }}</span>
                  </td>
                  <td class=" text-left" style="max-width: 100px !important; white-space: pre-wrap;">
                    <div class="text--secondary">
                        <span class="font-weight-bold">
                          Grade :
                        </span>
                      <span class="font-weight-medium">
                          {{ item.grade_title ? item.grade_title : '-' }}
                        </span>
                    </div>
                    <div class="text--secondary">
                        <span class="font-weight-bold">
                          Class :
                        </span>
                      <span class="font-weight-medium">
                          {{ item.class ? item.class : 'N/A' }}
                        </span>
                    </div>
                  </td>
                  <td class="">
                    <b>Total mark:</b> <span class="font-weight-medium">
                        {{ item.total_obtained_mark ? item.total_obtained_mark : 'NA ' }}
                      </span> <br />
                    <b>Total grade:</b> <span class="font-weight-medium">
                        {{ item.total_grade_point ? item.total_grade_point : 'NA' }}
                      </span><br />
                    <b>GPA:</b> <span class="font-weight-medium">
                        {{ item.total_gpa ? item.total_gpa : 'NA' }}
                      </span>
                    <br /> <span class="font-weight-medium">
                        <b>Attendance:</b> <span class="font-weight-medium">
                          {{ item.attendance ? item.attendance : 'NA' }}
                        </span>
                      </span>
                    <br /> <b>Division:</b> <span class="font-weight-medium">
                        {{ item.division ? item.division : 'NA' }}
                      </span>
                  </td>
                  <td class="text-center">
                      <span class="badge badge-info count_number_badge" v-if="item.rank"
                            v-bind:style="{ background: item.rank_color + '!important' }">
                        {{ item.rank ? item.rank : 'NA' }}
                      </span>
                    <span class="badge badge-info count_number_badge_depressed" v-else>
                        {{ item.rank ? item.rank : 'NA' }}
                      </span>
                  </td>

                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown size="sm" variant="link"
                                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                                  right no-flip>
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">

                          <b-dropdown-text tag="div" class="navi-item" v-if="item.result_status == 'published' ">
                            <a @click="openResultPdf(item.exam_id, item.student_id, 'certificate')" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                              <span class="navi-text">View result</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="item.result_status != 'published' ">
                            <a  class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                              <span class="navi-text">
                                <strike>View result</strike>
                              </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item">
                            <a @click="openAdmitCardPdf(item.exam_id, item.student_id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                              <span class="navi-text">View admit card</span>
                            </a>
                          </b-dropdown-text>
                        </div>

                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-if="exams.length == 0">
                  <td colspan="9" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ExamService from "@/core/services/exam/ExamService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";
import UserService from "@/core/services/user/UserService";

const user=new UserService();
const studentSetting=new StudentSettingService();
import {API_URL} from "@/core/config";

const examService = new ExamService;
export default {
  data(){
    return{
      loading:false,
      gradeLoading:false,
      exams: [],
      search:{
        name: '',
        grade_id: ''
      },
      grades: []
    }
  },
  methods:{
    openResultPdf(examId, studentId, type) {

      window.open(
          API_URL + "user/student/result/session/student/detail/" + examId + '/' + studentId + '/' + type,
          "_blank"
      );
    },
    openAdmitCardPdf(examId, studentId) {
      window.open(
          API_URL + "user/student/exams/" + examId + '/admitcard/preview/' + studentId,
          "_blank"
      );
    },
    getAllExams() {

      this.loading = true;
      examService
          .all(this.search)
          .then(response => {
            this.exams = response.data.exams;
          })
       .catch(error => {

      }).finally(() => {
        this.loading = false;
      });
    },

    getGrades() {
      this.search.student_grade_id=null;
      studentSetting
          .getAllStudentGrades()
          .then(response => {
            this.grades = response.data.data;
            if(this.grades.length > 0){
              this.search.grade_id=this.grades[0].grade_id;
              this.getAllExams()
            }

          }).catch((error) => {

      })
          .finally(() => {

          });
    },

  },
  mounted() {
    this.getGrades();
  }
}
</script>
