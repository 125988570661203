import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'
const apiService = Apiservice;


export default class UserService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user`;
    }

    getDigitalId() {
        let url = `${this.#api}/student/digital-id`
        return apiService.get(url);
    }
    requestGoogleAuthenticator() {
        let url = `${this.#api}/google-authenticator`
        return apiService.get(url);
    }

    activateGoogleAuthenticator(data) {
        let url = `${this.#api}/activate/google-authenticator`
        return apiService.post(url, data);
    }
    deActivateGoogleAuthenticator() {
        let url = `${this.#api}/deactivate/google-authenticator`
        return apiService.post(url);
    }

    checkVerificationEnabled(data) {
        let url = `${this.#api}/check/verification-enabled`
        return apiService.post(url, data);
    }
    changePassword(data) {
        let url = `${this.#api}/student/change-password`
        return apiService.post(url, data);
    }

    verifyGoogleVerificationCode(data) {
        let url = `${this.#api}/verify/google-verification-code`
        return apiService.post(url, data);
    }

    verifyEmailVerificationCode(data) {
        let url = `${this.#api}/verify/email-verification-code`
        return apiService.post(url, data);
    }
    sendMobileVerificationCode() {
        let url = `${this.#api}/send/verification-code`
        return apiService.get(url);
    }
    verifyMobile(data) {
        let url = `${this.#api}/verify/mobile`
        return apiService.post(url, data);
    }

    // update(id,data) {
    //     let url = `${this.#api}/user/${id}/update`
    //     return apiService.post(url, data);
    // }
    //
    // updateUser(id, data) {
    //     let url = `${this.#api}/user/${id}`
    //     return apiService.put(url, data);
    // }

    paginate(data,index) {
        let url = `${this.#api}/user`
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
            if (index)
                url = url + "&page=" + index
        } else {
            url = url + "?page=" + index
        }

        return apiService.get(url);

    }
    // store(data) {
    //     let url = `${this.#api}`
    //     return apiService.post(url, data);
    //
    // }

    show() {
        let url = `${this.#api}/student/info`
        return apiService.get(url);
    }

    // delete(id) {
    //     let url = `${this.#api}/${id}`
    //     return apiService.delete(url);
    // }

    logged() {
        let url = `${this.#api}/is/logged`
        return apiService.get(url);
    }

    requesEmailVerificationCode(data) {
        let url = API_URL + 'user/request/verification-code'
        return apiService.post(url, data);
    }

    requesRegistrationEmailVerificationCode(data) {
        let url = API_URL + 'user/resend/register/verification'
        return apiService.post(url, data);
    }

    // getTeachers() {
    //     let url = `${this.#api}/teacher/all`
    //     return apiService.get(url);
    // }
    //
    // assignRole(id, data) {
    //     let url = `${this.#api}/${id}/assign-role`
    //     return apiService.post(url, data);
    // }
    // removeRole(id, data) {
    //     let url = `${this.#api}/${id}/remove-role`
    //     return apiService.post(url, data);
    // }
    // userRoles(id) {
    //     let url = `${this.#api}/${id}/roles`
    //     return apiService.get(url);
    // }
    //
    // getStudentsByAcademicYearProgramAndSemester(academicYearId,programId,gradeId)
    // {
    //     let url = `${this.#api}/student/${academicYearId}/program/${programId}/semester/${gradeId}`
    //     return apiService.get(url);
    // }
    //
    // importUser(data){
    //     let url = `${this.#api}/import-student`;
    //     return apiService.post(url, data);
    // }
    userHistory() {
        let url = `${this.#api}/student/history`
        return apiService.get(url);
    }
}
